import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    timeZone: 'America/Sao_Paulo',
    // timeZone: "Asia/Beijing",
  },
  getters: {},
  mutations: {
    UPDATE_TIMEZONE(state, val) {
      state.timeZone = val
    },
  },
  actions: {
    updateTimeZone({ commit }, value) {
      commit('UPDATE_TIMEZONE', value.timeZone)
    },
  },
}
