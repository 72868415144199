import CryptoJS from 'crypto-js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import $http from '@/libs/http'
import storage from '@/libs/storage'
import $api from '@/api/index'
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js/mobile'

const moment = require('moment-timezone')

/**
 * @description currentTimezone
 * @param  {[string]}  time
 * @param  {[string]}  timeZone
 * @return {String}
 */
export function currentTimezone(time, timeZone = 'America/Sao_Paulo') {
  if (timeZone === 'America/Sao_Paulo') {
    return time + 3600 * 3 // 巴西时区 +3小时
  } if (timeZone === 'UTC') {
    return time
  } if (timeZone === 'Asia/Beijing') {
    return time - 3600 * 8
  }
  return ''
}

/**
 * @description handlerTimestamp
 * @param  {[string]}  t1
 * @param  {[string]}  t2
 * @param  {[string]}  timeZone
 * @return {String}
 */
export function handlerTimestamp(t1, t2, timeZone) {
  const t3 = moment.tz(t1, '').unix()
  const t4 = moment.tz(t2, '').unix()
  return [currentTimezone(t3, timeZone), currentTimezone(t4, timeZone)]
}

/**
 * 提示语
 * @param {Object} 当前页面的this
 * @param {String} variant
 * @param {String} message
 * icon danger： AlertTriangleIcon TrashIcon InfoIcon BellIcon success：CheckIcon CheckCircleIcon CoffeeIcon
 */
export function showToast({
  proxy, title = null, message = '', variant,
}) {
  const newVariant = variant || title || 'success'
  // eslint-disable-next-line eqeqeq
  const newTitle = title == 'danger' ? 'Waring' : title
  proxy.$toast({
    component: ToastificationContent,
    props: {
      title: `${newTitle || 'Error'}`,
      text: message,
      variant: newVariant,
      icon: newVariant === 'success' ? 'CheckIcon' : 'AlertTriangleIcon',
    },
  },
  {
    position: 'top-center',
  })
}

/**
 * 确保key的长度,使用 0 字符来补位
 * @param {*} key
 * @param {*} length 建议 16 24 32
 * @returns
 */
export function PaddingLeft(key, length) {
  let pkey = key.toString()
  const l = pkey.length
  if (l < length) {
    pkey = new Array(length - l + 1).join('0') + pkey
  } else if (l > length) {
    pkey = pkey.slice(length)
  }
  return pkey
}

/**
 * 对称明文加密
 * @param {*} msg 加密信息
 * @param {*} key 加密key 固定值
 * @returns
 */
export function aseEncrypt(msg) {
  let key = 'WdAgIPrQTeMJyaEb' // aes 对称加密的密钥  必须是16长度,为了和后端交互 key字符串必须是16进制字符串,否在给golang进行string -> []byte带来困难
  key = PaddingLeft(key, 16) // 保证key的长度为16byte,进行'0'补位
  key = CryptoJS.enc.Utf8.parse(key)
  // 加密结果返回的是CipherParams object类型
  // key 和 iv 使用同一个值
  const encrypted = CryptoJS.AES.encrypt(msg, key, {
    iv: key,
    mode: CryptoJS.mode.CBC, // CBC算法
    padding: CryptoJS.pad.Pkcs7, // 使用pkcs7 进行padding 后端需要注意
  })
  // ciphertext是密文,toString()内传编码格式,比如Base64,这里用了16进制
  // 如果密文要放在 url的参数中 建议进行 base64-url-encoding 和 hex encoding, 不建议使用base64 encoding
  return encrypted.ciphertext.toString(CryptoJS.enc.Hex) // 后端必须进行相反操作
}

/**
 * [trim 清空空格]
 * @param  {[string]}  value
 * @return {string}
 */
export const trim = value => {
  if (!value) return ''
  return value.replace(/(\s*)/g, '')
}

/**
 * [trim 清空空格]
 * @param  {[string]}  setDatetimeDefault
 * @return {string}
 */
export const setDatetimeDefault = (start, end) => {
  if (!start || !end) return
  // eslint-disable-next-line consistent-return
  return [
    moment().subtract(start, 'days').format('YYYY-MM-DD 00:00:00'),
    moment().subtract(end, 'days').format('YYYY-MM-DD 23:59:59'),
  ]
}

/**
 * @description updateDate 更新时区时间
 * @param  {[string]}  time
 * @param  {[string]}  timeZone 时区
 * @return {Boolean}
 */
export function updateDate(time, timeZone) {
  if (time === '') return ''
  let currentTimeZone = timeZone
  if (timeZone === 'Asia/Beijing') {
    currentTimeZone = 'Asia/Shanghai'
  }
  return moment
    .tz(time * 1000, currentTimeZone)
    .format('YYYY-MM-DD HH:mm:ss')
}

export function formatPhoneNumber86(phone) {
  if (!phone) return
  // eslint-disable-next-line consistent-return
  return `+${phone}`
}

export function formatPhoneNumber55(phone) {
  if (!phone) return
  const b = /([-+]?\d{1})(?=\d)/g
  // eslint-disable-next-line consistent-return
  return phone.replace(b, ($0, $1, n) => {
    switch (n) {
      case 0:
        return `+${$1}`
      case 1:
        return `${$1}(`
      case 3:
        return `${$1})`
      default:
        return $1
    }
  })
}

/**
 * @description formatPhoneNumber 更新时区时间
 * @param  {[string]}  phone
 * @return {String}
 */
export function formatPhoneNumber(phone) {
  if (!phone) return
  // 清楚数据中可能存在的字母和+ - 符号
  // eslint-disable-next-line no-param-reassign
  phone = phone.replace(/[A-Za-z+-]/g, '')
  const w = phone.substr(0, 2)
  if (w === '86') {
    // eslint-disable-next-line consistent-return
    return formatPhoneNumber86(phone)
  } if (w === '55') {
    // eslint-disable-next-line consistent-return
    return formatPhoneNumber55(phone)
  }
}

export async function updateNavMenuItems(proxy) {
  const res = await $api.getProfile()
  if (!res) return
  const { code, data } = res.data
  let newFeature = []
  if (code === 200) {
    let { data: { kyc_status: kycStatus } } = res.data
    const { feature_list } = data
    const {
      basic_info: basicInfo, account_info: accountInfo, merchant_key: merchantKey, user_info: userInfo,
    } = data
    storage.setItem('userData', {
      basicInfo, accountInfo, merchantKey, userInfo, kycStatus,
    })
    if (window.location.origin.includes('localhost')) { kycStatus = 4 }
    // 清空kyc信息
    storage.clearItem('kycBasicInfo')
    storage.clearItem('kycDocuments')
    storage.clearItem('kycDetail')
    if (kycStatus === 3 && proxy) {
      proxy.$swal({
        title: 'Registration Processing',
        text: 'Your account registration is currently under review. Please wait for approval.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/account/refreshing.png'),
        imageWidth: 88,
        imageAlt: 'Custom image',
        showConfirmButton: false,
        allowOutsideClick: false,
        customClass: {
          image: 'kyc-status-refresh',
        },
        position: 'center',
      }).then(result => {
        if (result.value) {
          // Remove userData from localStorage
          storage.clearItem('userData')
          // Redirect to login page
          proxy.$router.push({ name: 'auth-login' })
        }
      })
    } else if (kycStatus === 2 && proxy) {
      const kycRes = await $api.getKyc()
      if (!kycRes) return
      const { data: { data: kycInfo } } = kycRes
      const {
        basic_info, documents, details, reg_agreement_status,
      } = kycInfo
      // kyc审核不通过二次登陆要缓存数据
      storage.setItem('kycBasicInfo', { basic_info, reg_agreement_status })
      storage.setItem('kycDocuments', { documents })
      storage.setItem('kycDetail', { details })
    }
    // eslint-disable-next-line camelcase
    // feature_list = feature_list.concat([
    //   {
    //     feature_id: 'account', feature_name: 'Account', parent_feature_id: '', created_at_ms: '0', feature_list: [], perm: 1, description: '', icon: 'UserIcon', route: 'Account',
    //   }, {
    //     feature_id: 'transaction', feature_name: 'Transaction', parent_feature_id: '', created_at_ms: '0', feature_list: [], perm: 1, description: '', icon: 'DollarSignIcon', route: '',
    //   },
    //   {
    //     feature_id: 'transaction_list', feature_name: 'List', parent_feature_id: 'transaction', created_at_ms: '0', feature_list: [], perm: 1, description: '', icon: '', route: 'Transaction List',
    //   }, {
    //     feature_id: 'transaction_download', feature_name: 'Download', parent_feature_id: 'transaction', created_at_ms: '0', feature_list: [], perm: 1, description: '', icon: '', route: 'Transaction Download',
    //   },
    //   {
    //     feature_id: 'profile', feature_name: 'Profile', parent_feature_id: '', created_at_ms: '0', feature_list: [], perm: 1, description: '', icon: 'SettingsIcon', route: 'Profile',
    //   }, {
    //     feature_id: 'account_mgmt', feature_name: 'Account Mgmt', parent_feature_id: '', created_at_ms: '0', feature_list: [], perm: 1, description: '', icon: 'KeyIcon', route: '',
    //   }, {
    //     feature_id: 'user_management', feature_name: 'User Management', parent_feature_id: 'account_mgmt', created_at_ms: '0', feature_list: [], perm: 1, description: '', icon: '', route: 'User Management',
    //   }, {
    //     feature_id: 'role_management', feature_name: 'Role Management', parent_feature_id: 'account_mgmt', created_at_ms: '0', feature_list: [], perm: 1, description: '', icon: '', route: 'Role Management',
    //   }, {
    //     feature_id: 'activity_record', feature_name: 'Activity Record', parent_feature_id: 'account_mgmt', created_at_ms: '0', feature_list: [], perm: 1, description: '', icon: '', route: 'Activity Record',
    //   },
    // ])

    // 临时加
    // feature_list = feature_list.concat([
    //   {
    //     created_at_ms: '0',
    //     description: 'Prize Distribution',
    //     feature_id: 'prize_distribution',
    //     feature_list: [],
    //     feature_name: 'Prize Distribution',
    //     icon: 'AwardIcon',
    //     parent_feature_id: '',
    //     perm: 1,
    //     route: '',
    //   }, {
    //     created_at_ms: '0',
    //     description: 'List',
    //     feature_id: 'prize_distribution_list',
    //     feature_list: [],
    //     feature_name: 'List',
    //     icon: '',
    //     parent_feature_id: 'prize_distribution',
    //     perm: 1,
    //     route: 'Prize Distribution List',
    //   }, {
    //     created_at_ms: '0',
    //     description: 'Generator',
    //     feature_id: 'prize_distribution_generator',
    //     feature_list: [],
    //     feature_name: 'Generator',
    //     icon: '',
    //     parent_feature_id: 'prize_distribution',
    //     perm: 1,
    //     route: 'Prize Distribution Generator',
    //   },
    // ])

    // Download不需要单独授权，只要有可以下载数据页面的权限就有Download权限: deposit withdraw purchases
    //  || (value.feature_id === 'withdraw' && value.perm !== 0) || (value.feature_id === 'purchases' && value.perm !== 0) || (value.feature_id === 'prize_distribution_list' && value.perm !== 0) || (value.feature_id === 'prize_distribution_generator' && value.perm !== 0)
    const isDownload = feature_list.some(value => (value.feature_id === 'merchant_transaction_list' && value.perm !== 0))
    const parentNode = feature_list.filter(value => {
      // eslint-disable-next-line no-param-reassign
      value.title = value.feature_name
      if (value.feature_id === 'merchant_transaction_download') {
        // eslint-disable-next-line no-param-reassign
        value.perm = isDownload ? 1 : 0
      }
      return value.parent_feature_id === ''
    })

    newFeature = feature_list.reduce((prev, next) => {
      if (next.parent_feature_id !== '') {
        const index = prev.findIndex(feature => feature.feature_id === next.parent_feature_id)
        if (!prev[index].children) {
          // eslint-disable-next-line no-param-reassign
          prev[index].children = []
        }
        // eslint-disable-next-line no-unused-expressions
        const isExist = prev[index].children.some(value => value.feature_id === next.feature_id)
        if (!isExist) {
          // eslint-disable-next-line no-param-reassign
          next.title = next.feature_name
          prev[index].children.push(next)
        }
      }
      return prev
    }, parentNode)
    // 如果有子元素时，更新父元素的perm为 1   1：EDIT 2：VIEW
    newFeature.forEach(value => {
      if (value.children) {
        const isPermOne = value.children.some(child => (child.perm === 1 || child.perm === 2))
        // eslint-disable-next-line no-param-reassign
        value.perm = isPermOne ? 1 : 0
      }
    })
  }
  // console.log('后端数据生成的左侧目录=>', newFeature)
  // eslint-disable-next-line consistent-return
  return newFeature
}

export function getDefaultName() {
  // 获取权限下第一个子元素
  if (!storage.getItem('navMenuItems')) {
    storage.clearItem('navMenuItems')
    return []
  }
  const navMenuItems = JSON.parse(storage.getItem('navMenuItems'))
  // console.log('navMenuItems', navMenuItems)
  if (!navMenuItems) {
    storage.clearAll()
    return 'login'
  }
  // 可以跳转的全部route
  const premissionMenu = []
  navMenuItems.forEach(value => {
    if (value.children) {
      value.children.forEach(child => {
        if (child.perm !== 0) {
          premissionMenu.push(child.route)
        }
      })
    } else if (value.perm !== 0) {
      premissionMenu.push(value.route)
    }
  })
  // console.log('premissionMenu=> ', premissionMenu)
  // User Center http://localhost:8081/usercenter-list
  return premissionMenu
}

export const sliceStr = (str, len = 255) => str.slice(0, len)

export const subUrlName = (url = '') => url.substring(url.lastIndexOf('/') + 1)

export const initProfile = proxy => {
  // 请求 Profile
  $api.getProfile().then(async res => {
    // account_info,basic_info,domain,feature_list,ga_status,kyc_status
    let { data: { kyc_status: kycStatus } } = res.data
    const {
      data: {
        basic_info: basicInfo, account_info: accountInfo, merchant_key: merchantKey, user_info: userInfo,
      },
    } = res.data
    storage.setItem('userData', {
      basicInfo, accountInfo, merchantKey, userInfo, kycStatus,
    })
    // kyc_status: 0 未提交 2 拒绝 3 处理中 4 通过 5 重新上传
    // TODO：暂时给status赋值为 4
    if (window.location.origin.includes('localhost')) { kycStatus = 4 }
    if (kycStatus === 3 && !window.location.href.includes('/account-verification') && !window.location.href.includes('/login')) {
      proxy.$swal({
        title: 'Registration Processing',
        text: 'Your account registration is currently under review. Please wait for approval.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/account/refreshing.png'),
        imageWidth: 88,
        imageAlt: 'Custom image',
        showConfirmButton: false,
        allowOutsideClick: false,
        customClass: {
          image: 'kyc-status-refresh',
        },
        position: 'center',
      }).then(result => {
        if (result.value) {
          // Remove userData from localStorage
          storage.clearItem('userData')
          // Redirect to login page
          proxy.$router.push({ name: 'auth-login' })
        }
      })
    } else if (kycStatus === 2) {
      const kycRes = await $api.getKyc()
      if (!kycRes) return
      const { data: { data: kycInfo } } = kycRes
      const {
        basic_info, documents, details, reg_agreement_status,
      } = kycInfo
      // kyc审核不通过二次登陆要缓存数据
      storage.setItem('kycBasicInfo', { basic_info, reg_agreement_status })
      storage.setItem('kycDocuments', { documents })
      storage.setItem('kycDetail', { details })
      proxy.$router.push({ name: 'account-verification' })
    }
  })
}

const region = { 55: 'BR', 86: 'CN' }
export const formatPhone = (number, code) => {
  // By default, if a value is something like `"(123)"`
  // then Backspace would only erase the rightmost brace
  // becoming something like `"(123"`
  // which would give the same `"123"` value
  // which would then be formatted back to `"(123)"`
  // and so a user wouldn't be able to erase the phone number.
  // Working around this issue with this simple hack.
  if (number.length <= 3) {
    return number
  }
  return new AsYouType(code).input(number)
}

const validatePhoneLib = (number, code) => {
  const asYouType = new AsYouType(code)
  asYouType.input(number)
  // console.log(number, code, asYouType.getNumber()?.country);
  return isValidPhoneNumber(number, code) && asYouType.getNumber()?.country === code
}

export const checkIsPhoneValid = (val, areaCode) => validatePhoneLib(`${areaCode}${val}`, region[areaCode])
