import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import { setInteractionMode } from 'vee-validate'
import loadingRegister from '@/libs/loading/loading'
import certificationRegister from '@/libs/certificationQualification/index'
import previewPictrue from 'preview-pictrue'
import 'preview-pictrue/styles/index.css'
// eslint-disable-next-line import/order
import Viewer from 'v-viewer'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'viewerjs/dist/viewer.css'

import i18n from '@/libs/i18n'
import http from '@/libs/http'
import api from '@/api/index'
import storage from '@/libs/storage'
import Package from '@/package'
import mitt from 'mitt'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'

// Axios Mock Adapter
import '@/@fake-db/db'

// 创建Mitt实例
const Mit = mitt()
Vue.prototype.$Bus = Mit

Vue.use(loadingRegister)
Vue.use(certificationRegister)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(Viewer)
Vue.use(previewPictrue)

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(Package)

Vue.prototype.$http = http
Vue.prototype.$storage = storage
Vue.prototype.$api = api

// 修改为离焦生效
setInteractionMode('lazy')

Vue.directive('hasBtn', {
  // 当被绑定的元素插入到 DOM 中时……
  inserted(el, binding) {
    const { value } = binding
    // console.log(value)
    const navMenuItems = JSON.parse(storage.getItem('navMenuItems')) || []
    // console.log('store.state.wallet.navMenuItems=>', navMenuItems)
    const permissions = [] // 只有edit权限
    // 取所有子页面的 feature_id
    navMenuItems.forEach(menu => {
      if (menu.children) {
        menu.children.forEach(menuChild => {
          // 1：EDIT 2：VIEW edit时说明有按钮权限，才push
          if (menuChild.perm === 1) {
            permissions.push(`${menuChild.feature_id}`)
          }

          if (menuChild.feature_id === 'user_list' && (menuChild.perm === 1 || menuChild.perm === 2)) {
            permissions.push('link_user_list')
          }

          if (menuChild.feature_id === 'user_list' && menuChild.perm === 0) {
            permissions.push('no_user_list')
          }
        })
      } else if (menu.perm === 1) {
        permissions.push(`${menu.feature_id}`)
      }
    })
    // console.log('显示全量权限数据 =》', permissions)
    // ["merchant_account", "merchant_transaction_list", "merchant_transaction_download", "merchant_profile", "merchant_user_management", "merchant_role_management", "merchant_activity_record"]
    const hasPermissions = permissions.some(permission => value === permission)
    if (!hasPermissions) {
      el.parentNode.removeChild(el)
    }
  },
})

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
