/**
 * api管理
 */

import $http from '@/libs/http'

const baseUrlBean = require('@/libs/baseUrl')

export default {
  // 登陆注册
  metaCode(params) {
    return $http.post(`${baseUrlBean.proxy}${baseUrlBean.metaCode}`, params, {
      domainMerchant: 'open',
    })
  },
  sendSMS(params) {
    return $http.post(`${baseUrlBean.proxy}${baseUrlBean.sendSMS}`, params, {
      domainMerchant: 'open',
    })
  },
  register(params) {
    return $http.post(`${baseUrlBean.proxy}${baseUrlBean.register}`, params, {
      domainMerchant: 'open',
    })
  },
  login(params) {
    return $http.post(`${baseUrlBean.proxy}${baseUrlBean.login}`, params, {
      headers: { },
      domainMerchant: 'open',
    })
  },
  getQrcode({
    login_type, platform, user_name, password,
  }) {
    return $http.post(`${baseUrlBean.proxy}${baseUrlBean.getQrcode}`, {
      login_type, platform, user_name, password,
    }, {
      // responseType: 'blob',
      domainMerchant: 'open',
    })
  },
  upload(params) {
    return $http.post(`${baseUrlBean.proxy}${baseUrlBean.upload}`, params, {
      domainMerchant: 'open',
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
  // 获取profile
  getProfile() {
    return $http.get(`${baseUrlBean.proxy}${baseUrlBean.getProfile}?force_json=true`, {
      domainMerchant: 'open',
    })
  },
  // kyc
  saveKyc(params) {
    return $http.post(`${baseUrlBean.proxy}${baseUrlBean.postKyc}`, params)
  },
  getKyc() {
    return $http.get(`${baseUrlBean.proxy}${baseUrlBean.getKyc}?force_json=true`)
  },
  // *** 登陆之后 ***
  getAccount(params) {
    return $http.post(`${baseUrlBean.proxy}${baseUrlBean.account}?force_json=true`, params)
  },
  deposit(params) {
    return $http.post(`${baseUrlBean.proxy}${baseUrlBean.deposit}?force_json=true`, params)
  },
  withdraw(params) {
    return $http.post(`${baseUrlBean.proxy}${baseUrlBean.withdraw}?force_json=true`, params)
  },
  bankList(params) {
    return $http.post(`${baseUrlBean.proxy}${baseUrlBean.bankList}?force_json=true`, params)
  },
  resetPassword(params) {
    return $http.post(`${baseUrlBean.proxy}${baseUrlBean.resetPassword}`, params, {
      domainMerchant: 'open',
    })
  },
  postBank(params) {
    return $http.post(`${baseUrlBean.proxy}${baseUrlBean.postBank}`, params)
  },
  transactionList(params) {
    return $http.post(`${baseUrlBean.proxy}${baseUrlBean.transactionList}`, params)
  },
  transactionDownloadList() {
    return $http.post(`${baseUrlBean.proxy}${baseUrlBean.transactionDownloadList}?force_json=true`)
  },
  transactionDownloadSingle({ id, transaction_id }) {
    return $http.get(`${baseUrlBean.proxy}${baseUrlBean.transactionDownloadSingle}?id=${id}&transaction_id=${transaction_id}`, {
      domainMerchant: 'open',
      responseType: 'blob',
    })
  },
  transactionDetail({ transaction_id }) {
    return $http.get(`${baseUrlBean.proxy}${baseUrlBean.transactionDetail}?force_json=true&transaction_id=${transaction_id}`)
  },
  transactionRefund(params) {
    return $http.post(`${baseUrlBean.proxy}${baseUrlBean.transactionRefund}`, params)
  },
  // *** user management ***
  getAccountList({
    user_name, status, role_id, page_size, page_no,
  }) {
    return $http.post(`${baseUrlBean.proxy}${baseUrlBean.accountList}?force_json=true`, {
      user_name, status, role_id, page_size, page_no,
    })
  }, // 账户搜索
  saveAccount(params) {
    return $http.post(`${baseUrlBean.proxy}${baseUrlBean.saveAccount}?force_json=true`, params)
  }, // 账户保存
  resetPasswordSingle({ platform, user_id }) {
    return $http.post(`${baseUrlBean.proxy}${baseUrlBean.resetPasswordSingle}?force_json=true`, { platform, user_id })
  }, // 重置密码
  // *** role management ***
  fetchRoleList({
    role_name, role_id, page_size, page_no,
  }) {
    return $http.post(`${baseUrlBean.proxy}${baseUrlBean.fetchRoleList}?force_json=true`, {
      role_name, role_id, page_size, page_no,
    }, {
      domainMerchant: 'open',
    })
  }, // 角色搜索
  saveRole(params) {
    return $http.post(`${baseUrlBean.proxy}${baseUrlBean.saveRole}?force_json=true`, params, {
      domainMerchant: 'open',
    })
  }, // 角色保存
  removeRole({ role_id }) {
    return $http.post(`${baseUrlBean.proxy}${baseUrlBean.removeRole}?force_json=true`, { role_id }, {
      domainMerchant: 'open',
    })
  }, // 角色删除
  fetchFeatureList(params) {
    return $http.post(`${baseUrlBean.proxy}${baseUrlBean.fetchFeatureList}?force_json=true`, params, {
      domainMerchant: 'open',
    })
  }, // feature列表
  fetchRoleDetail({ role_id }) {
    return $http.post(`${baseUrlBean.proxy}${baseUrlBean.fetchRoleDetail}?force_json=true`, { role_id }, {
      domainMerchant: 'open',
    })
  }, // 角色详情
  // *** activity record ***
  fetchActivityList(params) {
    return $http.post(`${baseUrlBean.proxy}${baseUrlBean.activityList}?force_json=true`, params).catch(() => {})
  }, // 活动记录
}
