import vue from 'vue'
import certificationTip from './index.vue'

// 组件构造器，构造出一个 vue组件实例
const CertificationConstructor = vue.extend(certificationTip)
let certificationDom;

function showCertification() {
  if (certificationDom) {
    certificationDom.isShow = true;
    return false;
  }
  certificationDom = new CertificationConstructor({
    el: document.createElement('div'),
    data() {
      return {
        isShow: true
      }
    },
    methods: {
      confirm() {
        window.location.href = "https://www.smile.one/";
      },
    },
  })
  // 添加节点
  document.body.appendChild(certificationDom.$el)
}

function hideCertification() {
  certificationDom.isShow = false
}
// 全局注册
function registryCertificationTip() {
  vue.prototype.$showCertification = showCertification;
  vue.prototype.$hideCertification = hideCertification;
}

export default registryCertificationTip
