// import { $themeBreakpoints } from '@themeConfig'
import { updateNavMenuItems, getDefaultName } from '@/libs/utils'
import storage from '@/libs/storage'
import $api from '@/api/index'

export default {
  namespaced: true,
  state: {
    timeZone: 'America/Sao_Paulo',
    navMenuItems: (storage.getItem('navMenuItems') && storage.getItem('navMenuItems') !== 'undefined') ? JSON.parse(storage.getItem('navMenuItems')) : [],
    countryList: [],
    defaultRouteName: storage.getItem('defaultRouteName') || '',
    bankList: [],
    brazilBankList: [],
    mexicoBankList: [],
  },
  getters: {
    countrys: state => state.countryList,
  },
  mutations: {
    UPDATE_TIMEZONE(state, val) {
      state.timeZone = val
    },
    UPDATE_NAVMENUITEMS(state, value) {
      if (value) {
        setTimeout(() => {
          state.navMenuItems = []
          state.navMenuItems = value
        // const [defaultRouteName] = getDefaultName()
        // state.defaultRouteName = defaultRouteName
        // storage.setItem('defaultRouteName', defaultRouteName)
        }, 50)
      } else {
        setTimeout(async () => {
          state.navMenuItems = await updateNavMenuItems()
          storage.setItem('navMenuItems', JSON.stringify(state.navMenuItems))
        // const [defaultRouteName] = getDefaultName()
        // state.defaultRouteName = defaultRouteName
        // storage.setItem('defaultRouteName', defaultRouteName)
        }, 50)
      }
    },
    UPDATE_DEFAULTROUTENAME(state, val) {
      state.defaultRouteName = val
    },
    SET_COUNTRY_LIST: (state, value) => {
      state.countryList = value
    },
    SET_BANK_LIST: (state, value) => {
      state.bankList = value
    },
    SET_BRAZIL_BANK_LIST: (state, value) => {
      state.brazilBankList = value
    },
    SET_MEXICO_BANK_LIST: (state, value) => {
      state.mexicoBankList = value
    },
  },
  actions: {
    updateTimeZone({ commit }, value) {
      commit('UPDATE_TIMEZONE', value.timeZone)
    },
    changeNavMenuItems({ commit }, value) {
      commit('UPDATE_NAVMENUITEMS', value)
    },
    getCommon({ commit }) {
      return new Promise(resolve => {
        // 1:country 2:巴西bank 3:墨西哥bank
        $api.metaCode({ code_types: [1, 2, 3] }).then(res => {
          const { data } = res.data
          const countryList = data[0].code_list
          const brazilBankList = data[1].code_list
          const mexicoBankList = data[2].code_list

          const newCountryList = countryList.map(val => ({ label: val.name, value: val.code }))
          const newBrazilBankList = brazilBankList.map(val => ({ label: val.name, value: val.code }))
          const newMexicoBankList = mexicoBankList.map(val => ({ label: val.name, value: val.code }))

          commit('SET_COUNTRY_LIST', newCountryList)
          commit('SET_BRAZIL_BANK_LIST', newBrazilBankList)
          commit('SET_MEXICO_BANK_LIST', newMexicoBankList)
          resolve()
        })
      })
    },
    getBankList({ commit }) {
      return new Promise(resolve => {
        $api.bankList({ page_size: 9999, page_no: 1 }).then(res => {
          const { data } = res.data
          if (data && data.list.length > 0) {
            const newBankList = data.list.map(val => ({
              label: val.account_info.account_number, value: val.account_info.account_number, country: val.account_info.country, bank_code: val.account_info.bank_code,
            }))
            commit('SET_BANK_LIST', newBankList)
          }
          resolve(true)
        })
      })
    },
  },
}

// {
//     title: 'Access Control',
//     route: 'access-control',
//     icon: 'ShieldIcon',
//     // acl: {
//     action: 'read',
//     resource: 'Auth',
//     // },
// },
// {
//     title: 'Deposit Detail',
//     route: 'Deposit Details',
//     icon: 'MailIcon'
// },
// {
//     title: 'Withdraw Details',
//     route: 'Withdraw Details',
//     icon: 'MailIcon'
// },
// {
//     title: 'User Details',
//     route: 'User Details',
//     icon: 'MailIcon'
// },
// {
//     title: 'Channel Settings',
//     route: 'Channel Settings',
//     icon: 'SettingsIcon'
// }
