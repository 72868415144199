<template>
  <div>
    <span
      v-if="item.tType === 'transaction_list_id'"
      class="text-nowrap"
    >
      <b-link
        :to="{
          path: '/transaction-detail',
          query: { id: gdata.transaction_id, timezone: timeZone },
        }"
        class="font-weight-bold"
        target="_blank"
      >
        {{ gvalue }}
      </b-link>
    </span>
    <span
      v-if="item.tType === 'transaction_download_actions'"
      class="text-nowrap"
    >
      <b-button
        :disabled="gdata.url === ''"
        variant="success"
        class="btn-icon mr-1"
        size="sm"
        @click="() =>{ item.callback('download', gdata) }"
      ><feather-icon
        icon="DownloadIcon"
      /></b-button>
    </span>
    <span
      v-if="item.tType === 'account_proof_of_payment'"
      class="text-nowrap"
    >
      <span v-if="gdata.attachment === ''" />
      <b-button
        v-else
        variant="secondary"
        class="btn-icon mr-1"
        size="sm"
        @click="() =>{ item.callback('view',gdata) }"
      >
        view
      </b-button>
    </span>

    <span
      v-if="item.tType === 'account_list_amount'"
      class="text-nowrap"
    >
      R$ {{ Number(gvalue).toFixed(2) }}
    </span>

  </div>

</template>
<script>
import { defineComponent, computed, getCurrentInstance } from '@vue/composition-api'
import { BButton, BLink } from 'bootstrap-vue'
import { updateDate } from '@/libs/utils'

export default defineComponent({
  name: 'generator',
  props: ['item', 'gvalue', 'gdata'],
  setup() {
    const { proxy } = getCurrentInstance()
    const { $store } = proxy
    const timeZone = computed(() => $store.state.merchant.timeZone, { immediate: true, deep: true })
    return { updateDate, timeZone }
  },
  components: {
    BButton, BLink,
  },
})
</script>
