<template>
  <div class="loading-wrap"
       v-if="isShow">
    <p>Finish your account certification before using merchan center</p>
    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="confirm">
      Confirm
    </b-button>
  </div>
</template>
<script>
import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BButton,
  },
  directives: {
    Ripple,
  },
};
</script>

<style lang="scss" scoped>
.loading-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  // background: transparent;
  background-color: rgba(51, 51, 51, 0.8);
  text-align: center;
  p {
    text-align: center;
    margin-top: 15%;
    color: #ffffff;
    font-weight: bold;
    font-size: 24px;
  }
  button {
    margin-top: 5%;
  }
}
</style>
