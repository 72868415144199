// axios
import axios from 'axios'
import qs from 'qs'
import storage from '@/libs/storage'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 120000,
  headers: { 'Content-type': 'application/json' }, // application/x-www-form-urlencoded
})

// 添加请求拦截器
instance.interceptors.request.use(config => {
  if (config.domainMerchant === 'open') {
    // eslint-disable-next-line no-param-reassign
    config.baseURL = process.env.VUE_APP_BASE_OPEN_API
  }
  const accessToken = storage.getItem('accessToken') // Get token from localStorage
  if (accessToken) { // If token is present add it to request's Authorization Header
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `${accessToken}`
  }
  return config
}, error => {
  Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(response => {
  // 对响应数据做点什么
  if (response.data.code === 401 || response.data.code === 100000) {
    storage.clearAll()
    window.location.href = `${window.location.origin}/login`
    return
  }
  // eslint-disable-next-line consistent-return
  return response
}, error => {
  // 对响应错误做点什么
  const err = qs.parse(error).response
  let errorInfo = {}
  if (err && typeof err.data === 'object') {
    errorInfo = err.data
  } else {
    errorInfo = {
      code: 0,
      data: null,
      errorCode: 9999,
      requestId: '',
    }
    if (typeof err.data === 'string') {
      errorInfo.message = err.data
    } else {
      errorInfo.message = 'internal error'
    }
  }
  return Promise.reject(errorInfo)
})

export default instance
