export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/account-verification',
    name: 'account-verification',
    component: () => import('@/views/pages/accountVerification/index.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/access-control',
    name: 'access-control',
    component: () => import('@/views/AccessControl.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Access Control',
      breadcrumb: [
        {
          text: 'Access Control',
          active: true,
        },
      ],
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('@/views/account/Account.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Account',
      breadcrumb: [
        {
          text: 'Account',
          active: true,
        },
      ],
    },
  },
  {
    path: '/account-detail',
    name: 'Account Details',
    component: () => import('@/views/account/accountDetail.vue'),
    meta: {
      layout: 'full',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/transaction-list',
    name: 'Transaction List',
    component: () => import('@/views/transaction/Transaction.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Transaction',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/transaction-download',
    name: 'Transaction Download',
    component: () => import('@/views/transaction/TransactionDownload.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Transaction',
      breadcrumb: [
        {
          text: 'Download',
          active: true,
        },
      ],
    },
  },
  {
    path: '/transaction-detail',
    name: 'Transaction Details',
    component: () => import('@/views/transaction/transactionDetail.vue'),
    meta: {
      layout: 'full',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/profile/Profile.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Profile',
          active: true,
        },
      ],
    },
  },
]
