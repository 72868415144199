const baseUrlBean = require("@/libs/baseUrl");

export default {
  // Endpoints
  // loginEndpoint: '/jwt/login',
  loginEndpoint: `${baseUrlBean.proxy}${baseUrlBean.login}`,
  // registerEndpoint: '/jwt/register',
  registerEndpoint: `${baseUrlBean.proxy}${baseUrlBean.register}`,
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
