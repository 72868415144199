module.exports = {
  proxy: '/api/v1',
  metaCode: '/open/meta', // 加载国家数据
  sendSMS: '/open/sms_code', // 发送手机验证码
  register: '/open/merchant/register', // 商户注册
  login: '/open/auth/login', // 登陆
  getQrcode: '/open/auth/google_qrcode',
  upload: '/open/upload',
  postKyc: '/merchant/kyc', // 保存商户KYC信息
  getKyc: '/merchant/kyc', // 获取商户KYC信息
  getProfile: '/open/merchant/profile', // 获取商户信息
  // *** 登陆之后 ***
  account: '/merchant/account', // 获取商户账户信息
  deposit: '/merchant/deposit', // 充值
  withdraw: '/merchant/withdraw', // 提现
  bankList: '/merchant/bank_list', // 银行列表
  resetPassword: '/open/auth/reset_password', // 重置密码
  postBank: '/merchant/bank', // 添加银行
  transactionList: '/merchant/transaction_list', // 订单列表
  transactionDownloadList: '/merchant/transaction_download_list', // 下载列表
  transactionDetail: '/merchant/transaction_detail', // 下载列表
  transactionRefund: '/merchant/transaction_refund',
  transactionDownloadSingle: '/open/download',
  // *** user management ***
  accountList: '/merchant/account/list',
  saveAccount: '/merchant/account/save',
  resetPasswordSingle: '/merchant/account/reset_password',
  // *** role management ***
  fetchRoleList: '/open/role/list',
  saveRole: '/open/role/save',
  removeRole: '/open/role/remove',
  fetchFeatureList: '/open/feature/list',
  fetchRoleDetail: '/open/role/fetch',
  // *** activity record ***
  activityList: '/merchant/activity/list',

  // ************************* 没有使用的接口 *********************************
  // 忘记密码
  forgetPassword: '/merchant/auth/forget_password',
  // 保存商户信息
  postProfile: '/merchant/profile',
  // 银行详情
  getBank: '/merchant/bank',
  // 订单详情

  // 以下为旧接口
  // proxy2: '/api/v2',
  // // login: '/wallet/auth/login', // 登录
  // // register: '/merchant/register', // 商户注册
  // smsCode: '/wallet/sms_code/send', // 发送验证码
  // // forgetPassword: '/wallet/auth/forget_password', // 忘记密码
  // // resetPassword: '/wallet/auth/reset_password', // 重置密码 +++++++++++
  // merchantProfile: '/merchant/profile/123', // 获取商户profile GET/保存商户Profile POST 完善信息

  // merchantAccount: '/merchant/account', // 商户账户
  // merchantTransactionList: '/merchant/transaction_list', // 商户交易列表
  // merchantTransactionDetail: '/merchant/transaction_detail', // 商户交易详情
  // merchantBank: '/merchant/bank',
  // merchantBankList: '/merchant/bank_list',
  // test: '', // 暂时没有接口
}
