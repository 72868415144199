<template>
  <b-card>
    <b-table
      ref="refTableName"
      :items="list"
      responsive
      :fields="tableBean.tableColumns"
      primary-key="roleId"
      show-empty
      empty-text="No matching records found"
      class="position-relative"
    >
      <template
        v-for="(item,index) in tableBean.templates"
        #[`cell(${item.key})`]="data"
      >
        <!-- 统一处理时间 -->
        <span
          v-if="item.tType === 'datetime'"
          :key="index"
          class="text-nowrap"
          style="cursor: pointer;"
        > {{ updateDate(data.value, timeZone) }}</span>
        <!-- 操作按钮分组处理 -->
        <!-- transaction 模块 -->
        <generator
          v-else-if="item.tType !== 'datetime' && item.tType.includes('transaction')"
          :key="index"
          :gvalue="data.value"
          :item="item"
          :gdata="data.item"
        />
        <!-- account 模块 -->
        <generator
          v-else-if="item.tType !== 'datetime' && item.tType.includes('account')"
          :key="index"
          :gvalue="data.value"
          :item="item"
          :gdata="data.item"
        />
        <span
          v-else
          :key="index"
          class="text-nowrap"
        > {{ data.value }}</span>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span
            class="text-muted mr-1"
          >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
            {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="pageNum"
            :total-rows="totalList"
            :per-page="pageSize"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="changePagination"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>
<script>
import {
  defineComponent, ref, getCurrentInstance, computed,
} from '@vue/composition-api'
import {
  BCard, BForm, BRow, BCol, BInputGroup, BFormInput, BTable, BPagination, BInputGroupPrepend, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { updateDate } from '@/libs/utils'

// components
import Generator from './components/generator.vue'

export default defineComponent({
  name: 'TableList',
  props: ['tableData', 'list', 'pageNum', 'totalList'],
  setup(props, context) {
    const { proxy } = getCurrentInstance()
    const { $store } = proxy
    const tableBean = ref({ ...props.tableData })
    const { list } = tableBean.value
    const refTableName = ref(null)
    const pageSize = ref(10)

    const recordList = ref([list])
    const dataMeta = computed(() => {
      const localItemsCount = refTableName.value ? refTableName.value.localItems.length : 0
      return {
        from: pageSize.value * (props.pageNum - 1) + (localItemsCount ? 1 : 0),
        to: pageSize.value * (props.pageNum - 1) + localItemsCount,
        of: props.totalList,
        total: props.totalList,
      }
    })
    const timeZone = computed(() => $store.state.merchant.timeZone, { immediate: true, deep: true })
    const changePagination = page => {
      context.emit('update:pageNum', page)
    }
    return {
      tableBean,
      refTableName,
      pageSize,
      recordList,
      dataMeta,
      timeZone,
      changePagination,

      updateDate,
    }
  },
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BTable,
    BPagination,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,

    Generator,
  },
})
</script>
