import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
// eslint-disable-next-line import/no-cycle
import { getDefaultName, updateNavMenuItems } from '@/libs/utils'
import storage from '@/libs/storage'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
// import apps from './routes/apps'
// import dashboard from './routes/dashboard'
// import uiElements from './routes/ui-elements/index'
// import pages from './routes/pages'
// import chartsMaps from './routes/charts-maps'
// import formsTable from './routes/forms-tables'
// import others from './routes/others'
import indexs from './routes/index'
import accountMgmt from './routes/accountMgmt'

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) { return originalPush.call(this, location, onResolve, onReject) }
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) { return originalReplace.call(this, location, onResolve, onReject) }
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // { path: '/', redirect: { name: 'account' } },
    { path: '/', redirect: { name: store.state.wallet.defaultRouteName || storage.getItem('defaultRouteName') || 'auth-login' } },
    ...indexs,
    ...accountMgmt,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next('/')
  }
  // 'Transaction Details'
  const isLeftMenu = ['auth-login', 'auth-forgot-password', 'auth-register', 'account-verification', 'Transaction Details'].includes(to.name)
  if (isLeftMenu) {
    return next()
  }
  if (to.name !== 'auth-login' && !isLeftMenu) {
    // 实时更新权限
    // const navMenuItems = await updateNavMenuItems()
    // storage.setItem('navMenuItems', JSON.stringify(navMenuItems))
    // store.dispatch('wallet/changeNavMenuItems', navMenuItems)

    // 左侧一级目录
    const defaultRouteNames = getDefaultName()
    storage.setItem('defaultRouteName', defaultRouteNames[0])
    if (!(defaultRouteNames.includes(to.name))) {
      // 如果 包含在左侧一级目录 不走这里；如果不包含左侧一级目录且是详情，不走这里；只有不包含左侧一级目录且不是详情才走这里
      router.push({ name: defaultRouteNames[0] })
    }
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
